<template>
  <v-container fluid>
    <v-card v-if="apresentarPesquisaLoja">
      <v-row>
        <v-col
          cols="12"
          md="3"
          class="ml-2"
        >
          <compLoja
            :dense="true"
            carregarlojainicial
            @onSelectLoja="onSelectLoja"
            @onLojaInicial="onLojaInicial"
          />
        </v-col>
      </v-row>
    </v-card>
    <!-- <v-row dense>
      <v-col cols="12">
        <apexchart
          v-if="CarregaMetasVendedor"
          height="400"
          :options="optionsMetasVendedor"
          :series="seriesMetasVendedor"
        />
      </v-col>
      <v-col cols="12">
        <apexchart
          v-if="CarregaMetasVendedor"
          height="400"
          :options="optionsMetasVendedor"
          :series="seriesMetasVendedor"
        />
      </v-col>
    </v-row> -->
    <v-row dense>
      <v-col cols="12">
        <apexchart
          v-if="CarregaVendasMes"
          height="300"
          :options="optionsVendasAno"
          :series="seriesTotal"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="CarregaVendasMes"
      dense
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-row
          dense
        >
          <v-col cols="6">
            <v-select
              v-model="selectMes"
              :items="listaMeses"
              solo
              dense
              hide-details
              label="Selecione o Mês"
              item-text="mes"
              item-value="id"
              @change="onChangeMes"
            />
          </v-col>
        </v-row>
        <v-card
          elevation="5"
          class="ma-0"
        >
          <v-card-title class="blue darken-3 white--text">
            Total por Vendedor - {{ mesAtual }}
          </v-card-title>
          <v-virtual-scroll
            :bench="benched"
            :items="listaTotalVendasVendedor"
            height="300"
            item-height="50"
          >
            <template v-slot:default="{ index, item }">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col cols="6">
                        <strong>{{ item.nmVendedor }}</strong>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex justify-end"
                      >
                        <strong><span class="pr-1">R$</span>{{ formatPrice(item.total) }}</strong>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          elevation="5"
          class="ma-0"
        >
          <v-card-title class="blue darken-3 white--text">
            Top 5 Vendedores - {{ anoAtual }}
          </v-card-title>
          <v-virtual-scroll
            :bench="benched"
            :items="listaTotalVendasTopVendedor"
            height="346"
            item-height="50"
          >
            <template v-slot:default="{ index, item }">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col cols="6">
                        <strong>{{ item.nmVendedor }}</strong>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex justify-end"
                      >
                        <strong><span class="pr-1">R$</span>{{ formatPrice(item.total) }}</strong>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import dashboardRep from '../components/services/dashboardRepository'
  import transacaoRep from '../components/services/transacaoRepository'
  import CaixaRep from '../components/services/caixaRepository'
  import VendedorRep from '../components/services/vendedorRepository'
  import compLoja from './componentes/componente-loja.vue'

  export default {
    components: {
      compLoja,
    },
    data () {
      return {
        title: 'Home',
        benched: 1000,
        options: {
          chart: {
            id: 'totaVendas',
            type: 'area',
          },
          xaxis: {
            categories: [],
          },
          title: {
            text: 'Vendas por Mês',
          },
        },
        optionsVendasAno: {
          chart: {
            id: 'totaVendasAno',
            type: 'area',
          },
          yaxis: {
            labels: {
              /**
              * Allows users to apply a custom formatter function to yaxis labels.
              *
              * @param { String } value - The generated value of the y-axis tick
              * @param { index } index of the tick / currently executing iteration in yaxis labels array
              */
              formatter: function (val, index) {
                var value = (val / 1).toFixed(2).replace('.', ',')
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              },
            },
          },
          xaxis: {
            categories: [],
            labels: {
              formatter: function (value) {
                return value
              },
            },
          },
          title: {
            text: 'Vendas no Ano',
          },
        },
        seriesMetasVendedor: [{
          name: 'Vendas',
          type: 'column',
          data: [300382.96, 186496.07, 215517.20, 171166.68, 352542.42, 272952.66],
        }, {
          name: 'Metas',
          type: 'column',
          data: [300000, 160000, 240000.00, 250000.00, 380000.00, 200000.00],
        }],
        optionsMetasVendedor: {
          chart: {
            height: 350,
            type: 'line',
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [1, 3],
            // colors: ['#8B0000'],
          },
          title: {
            text: 'Metas do Mês',
            align: 'left',
            offsetX: 110,
          },
          xaxis: {
            categories: ['Bruna Letícia', 'Caroline Nunes', 'Diogenes', 'Geysla', 'Isleide', 'Jose Almir'],
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008FFB',
              },
              labels: {
                style: {
                  colors: '#008FFB',
                },
              },
              title: {
                text: 'Meta',
                style: {
                  color: '#008FFB',
                },
              },
              tooltip: {
                enabled: true,
              },
            },
            {
              seriesName: 'Metas',
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#008FFB',
              },
              labels: {
                style: {
                  colors: '#008FFB',
                },
              },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40,
          },
        },
        series: [],
        seriesTotal: [],
        CarregaMetasVendedor: false,
        CarregaVendasMes: false,
        dadosMetaVendedor: [],
        dadosTotalVendasVendedor: [],
        dadosTotalVendasAno: [],
        listaTotalVendasVendedor: [],
        listaTotalVendasTopVendedor: [],
        listaMeses: [],
        selectMes: 0,
        mesAtual: '',
        apresentarPesquisaLoja: false,
        anoAtual: 0,
      }
    },
    async mounted () {
      this.carregarDadosCaixa()
      this.carregarDadosVendedor()
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        // VERIFICA SE USUARIO TEM PERMISSÃO
        if (await this.getTransacao()) {
          await this.dashboardCarregarDados()
        }
      } else {
        await this.dashboardCarregarDados()
      }
    },
    methods: {

      // async metasVendedorCorrente (loja, mes, ano) {
      //   this.$store.commit('setSplashScreen', true)
      //   this.CarregaMetasVendedor = false
      //   await dashboardRep.getListaMetaVendedor(loja, ano, mes).then(response => {
      //     // listaUsuarios = response.data
      //     this.$store.commit('setSplashScreen', false)
      //     this.dadosMetaVendedor = response.data
      //     this.onCarregarMetasVendedorGrid()
      //     this.CarregaMetasVendedor = true
      //   }).catch(error => {
      //     this.$store.commit('setSplashScreen', false)
      //     this.$swal({
      //       icon: 'warning',
      //       text: error,
      //     })
      //   })
      // },
      async carregarDadosCaixa () {
        this.caixa = {
          cdUsuario: this.$store.getters.usuario.cdusuario,
        }
        await CaixaRep.getDadosCaixa(this.caixa).then(resp => {
          if (resp.data.dados !== null) {
            this.$store.commit('SET_CAIXA', resp.data.dados)
          } else {
            this.$store.commit('SET_CAIXA', null)
          }
        }).catch(resp => {
        })
      },
      async carregarDadosVendedor () {
        await VendedorRep.getVendedor(this.$store.getters.usuario.cdusuario).then(resp => {
          if (resp.data !== null) {
            this.$store.commit('SET_VENDEDOR', resp.data)
          } else {
            this.$store.commit('SET_VENDEDOR', null)
          }
        }).catch(resp => {
        })
      },
      dashboardCarregarDados () {
        this.apresentarPesquisaLoja = true
        this.$store.commit('setSplashScreen', true)
        setTimeout(() => {
          // this.$refs.txtcodigo.focus()
          this.carregarDados()
        }, 3500)
      },
      carregarDados () {
        this.selectMes = new Date().getMonth() + 1
        this.anoAtual = new Date().getFullYear()
        this.getTotalVendaVendedor(this.loja, new Date().getFullYear(), new Date().getMonth(), 0)
        this.getTotalVendaAno(this.loja, new Date().getFullYear())
        this.getTotalTopVendedorAno(this.loja, new Date().getFullYear())
        // this.metasVendedorCorrente(this.loja, this.selectMes, this.anoAtual)
      },
      async getTotalVendaVendedor (loja, ano, mes, vendedor) {
        this.$store.commit('setSplashScreen', true)
        await dashboardRep.getListaVendedorVendas(loja, ano, mes, vendedor).then(response => {
          // listaUsuarios = response.data
          if (response.data.status === 'sucesso') {
            this.$store.commit('setSplashScreen', false)
            if (response.data.dados.length > 0) {
              this.dadosTotalVendasVendedor = response.data.dados
              this.onChangeMes()
              this.onCarregarMeses()
              setTimeout(() => {
                // this.$refs.txtcodigo.focus()
                this.CarregaVendasMes = true
              }, 500)
            }
          } else {
            this.$swal({
              icon: 'warning',
              text: response.data.messagem + ' - ' + response.data.dados,
            })
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'warning',
            text: error,
          })
        })
      },
      async getTotalVendaAno (loja, ano) {
        this.$store.commit('setSplashScreen', true)
        await dashboardRep.getListaVendasAno(loja, ano).then(response => {
          // listaUsuarios = response.data
          if (response.data.status === 'sucesso') {
            this.$store.commit('setSplashScreen', false)
            if (response.data.dados.length > 0) {
              this.dadosTotalVendasAno = response.data.dados
              this.totalVendaAno()
              setTimeout(() => {
                // this.$refs.txtcodigo.focus()
                this.CarregaVendasMes = true
              }, 500)
            } else {
              this.CarregaVendasMes = false
              this.$swal({
                icon: 'warning',
                text: 'Sem dados para a Loja Selecionada!',
              })
            }
          } else {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.messagem + ' - ' + response.data.dados,
            })
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'warning',
            text: error,
          })
        })
      },
      async getTotalTopVendedorAno (loja, ano) {
        await dashboardRep.getListaTopVendedorVendasAno(loja, ano).then(response => {
          // listaUsuarios = response.data
          if (response.data.status === 'sucesso') {
            this.$store.commit('setSplashScreen', false)
            if (response.data.dados.length > 0) {
              this.listaTotalVendasTopVendedor = response.data.dados
              setTimeout(() => {
                this.CarregaVendasMes = true
              }, 500)
            } else {
              this.CarregaVendasMes = false
              this.$swal({
                icon: 'warning',
                text: 'Sem dados para a Loja Selecionada!',
              })
            }
          } else {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.messagem + ' - ' + response.data.dados,
            })
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'warning',
            text: error,
          })
        })
      },
      totalVendaAno () {
        this.seriesTotal = []
        this.optionsVendasAno.xaxis.categories = this.dadosTotalVendasAno.map(a => a.descMes)
        this.seriesTotal.push({
          name: 'Total de Vendas',
          data: this.dadosTotalVendasAno.map((dados) => {
            return dados.total
          }),
        })
      },
      updateChart () {
        this.series = []
        this.options.xaxis.categories = this.dadosVenda.map(a => a.descmes)
        // DISTINCT DOS VENDEDORES
        var ListaVendedores = Array.from(new Set(this.dadosVenda.map(s => s.cdVendedor)))
          .map(id => {
            return {
              id: id,
            }
          }).sort()

        ListaVendedores.map((dados) => {
          var dadosVendedor = this.dadosVenda.filter(v => v.cdVendedor === dados.id)
          this.series.push({
            name: dadosVendedor[0].nmVendedor,
            data: dadosVendedor.map((dados) => {
              return dados.total
            }),
          })
        })
      },
      onChangeMes () {
        if (this.dadosTotalVendasVendedor.length > 0) {
          this.mesAtual = this.dadosTotalVendasVendedor.find(v => v.mes === this.selectMes).descMes
          this.listaTotalVendasVendedor = this.dadosTotalVendasVendedor.filter(v => v.mes === this.selectMes)
          this.listaTotalVendasVendedor.sort((a, b) => a.total - b.total).reverse()
        }
      },
      distinctVendedores (value, index, self) {
        return self.indexOf(value) === index
      },
      onCarregarMeses () {
        this.listaMeses = Array.from(new Set(this.dadosTotalVendasVendedor.map(s => s.mes)))
          .map(id => {
            return {
              id: id,
              mes: this.dadosTotalVendasVendedor.find(s => s.mes === id).descMes,
            }
          }).sort((a, b) => a.id - b.id)
      },
      onSelectLoja (loja) {
        this.loja = loja.cdLoja
        this.carregarDados()
      },
      onLojaInicial (loja) {
        this.loja = loja
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FA002DAS',
        }
        await transacaoRep.get(dados).then(response => {
          if (response.data.status === 'mensagem') {
            // this.$store.commit('setSplashScreen', false)
            // this.$swal({
            //   icon: 'warning',
            //   text: response.data.dados,
            //   confirmButtonText: 'OK',
            // }).then((result) => {
            //   if (result.isConfirmed) return false
            // })
            this.$store.commit('setSplashScreen', false)
            return false
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) return false
            })
          }
          return true
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      onCarregarMetasVendedorGrid () {
        // Vendas
        this.seriesMetasVendedor[0].data = []
        // Metas
        this.seriesMetasVendedor[1].data = []

        // Nomes Vendedor Limpando Array

        this.optionsMetasVendedor.xaxis.categories = []

        // Preenchendo as Vendas
        for (let index = 0; index < this.dadosMetaVendedor.length; index++) {
          this.seriesMetasVendedor[0].data.push(this.dadosMetaVendedor[index].totalVendido)
        }
        // Preenchendo as Metas
        for (let index = 0; index < this.dadosMetaVendedor.length; index++) {
          this.seriesMetasVendedor[1].data.push(this.dadosMetaVendedor[index].vlMeta)
        }
        // Nomes dos Vendedores
        for (let index = 0; index < this.dadosMetaVendedor.length; index++) {
          this.optionsMetasVendedor.xaxis.categories.push(this.dadosMetaVendedor[index].nmVendedor)
        }
      },
    },
  }
</script>
